import React from 'react'

export default function Loading() {
  const containerStyle = {
    height: '100%',
    width: '100%',
  }

  return (
    <div className="d-flex align-items-center justify-content-center" style={containerStyle}>
      <h1>Logging you in . . .</h1>
    </div>
  )
}
