import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { fullTextFilter, PageHeaderHeight } from '../../../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
  renderFilterInput,
} from '../../../UsersPage/index.screen'

export const DropDownButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 8px;
`

class OrganizationsListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { organizations, height, onOrganization, goToOta } = this.props

    const columns = [
      {
        id: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => <CellContainer>{original.name}</CellContainer>,
        accessor: 'name',
        sortable: true,
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'subdomain',
        Header: () => renderHeaderCell('Subdomain'),
        Cell: ({ original }) => (
          <CellContainer>{original.subdomain}</CellContainer>
        ),
        accessor: 'subdomain',
        sortable: true,
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'contactName',
        Header: () => renderHeaderCell('Contact Name'),
        Cell: ({ original }) => (
          <CellContainer>{original.contactName}</CellContainer>
        ),
        accessor: 'contactName',
        sortable: true,
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]
    return (
      <div style={{ height: '100%' }}>
        <HeaderContainer className="border-bottom">
          <Header>Organizations</Header>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{
            height: height - PageHeaderHeight,
          }}
          data={organizations}
          columns={columns}
          sortable={false}
          defaultFilterMethod={fullTextFilter}
          getTdProps={(state, rowInfo) => ({
            onClick: (e, handleOriginal) => {
              onOrganization(rowInfo.original)

              if (handleOriginal) {
                handleOriginal()
              }
            },
          })}
        />
      </div>
    )
  }
}

OrganizationsListScreen.propTypes = {
  organizations: PropTypes.array.isRequired,
  selectedOrganizationId: PropTypes.string,
  onSelectOrganization: PropTypes.func.isRequired,
  height: PropTypes.number,
  onOrganization: PropTypes.func,
  goToOta: PropTypes.func,
}

export default OrganizationsListScreen
