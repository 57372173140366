/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { login, adminLogin, loginError, submitOTP } from 'containers/App/actions'
import { selectUserError } from 'containers/App/selectors'
import { Routes } from '../App/constants'
import styles from '../../styles/App.css'
import ErrorMessage from './ErrorMessage'
import { emailValidationPattern } from '../../utils/utils'
export class LoginContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { errors: {}, isModalOpen: false }
  }

  componentDidMount() {
    setTimeout(() => {
      this.email.focus()
    }, 250)

    const isLoggedOutDueToInactivity = localStorage.getItem('loggedOutDueToInactivity')
    if (isLoggedOutDueToInactivity) {
      this.setState({ isModalOpen:true })
    }
  }

  onClick = (e) => {
    e.preventDefault()
    const {
      login: doLogin,
      adminLogin: doAdminLogin,
      loginError: doLoginError,
      history: { location: { pathname } },
    } = this.props
    const { errors } = this.state
    const email = this.email.value
    const password = this.password.value
    let validationMsg
    if (!email && !password) {
      validationMsg = 'The Email and Password fields are empty'
    } else if (!email) {
      validationMsg = 'The Email field is empty'
    } else if (!password) {
      validationMsg = 'The Password field is empty'
    }
    if (errors.email && errors.password) {
      validationMsg = `${errors.email} ${errors.password}`
    } else if (errors.email) {
      validationMsg = errors.email
    } else if (errors.password) {
      validationMsg = errors.password
    }
    if (validationMsg) {
      doLoginError(validationMsg)
    } else {
      if (pathname === Routes.LOGIN) {
        doLogin({
          email: this.email.value,
          password: this.password.value,
        })
      } else if (pathname === Routes.ADMIN_LOGIN) {
        doAdminLogin({
          email: this.email.value,
          password: this.password.value,
        })
      }
    }
  }

  onKeyPress = ({ key, target }) => {
    if (key === 'Enter') {
      if (target.name === 'email') {
        if (this.password) {
          this.password.focus()
        }
        return
      }
      this.onClick(event)
    }
  }

  renderInputs() {
    return (
      <form className="needs-validation was-validated">
        <div className="form-group">
          <input
            pattern={emailValidationPattern}
            ref={(ref) => (this.email = ref)}
            className={`form-control ${styles.username}`}
            name="email"
            placeholder="Enter your email"
            onKeyPress={this.onKeyPress}
          />
          <div className="invalid-feedback">
            Please enter a valid email address.
          </div>
        </div>
        <div>
          <input
            ref={(ref) => (this.password = ref)}
            className={`form-control ${styles.username}`}
            name="password"
            placeholder="Enter your password"
            type="password"
            onKeyPress={this.onKeyPress}
          />
        </div>
        <button
          style={{ width: '140px' }}
          className={styles.button}
          onClick={this.onClick}
          name="login"
          type="submit"
        >
          Login
        </button>
      </form>
    )
  }

  renderError() {
    const { error } = this.props
    const isLockedOut = error.body?.error?.validationErrors?.base?.includes('locked_out')
    const message = isLockedOut ? 'This account has been locked out due to too many failed login attempts. Please speak to an admin to unlock it.' : error.message
    return message ? <ErrorMessage error={message} /> : null
  }

  otpToken() {
    return this.props.error?.body?.error?.validationErrors?.authentication?.token
  }

  onOTPEntered(e) {
    e.preventDefault()

    const {
      submitOTP
    } = this.props
    const otp = this.otpInput.value
    const token = this.otpToken()

    submitOTP({ otp, token })
  }

  renderOTP() {
    return (
      <div className={styles.login}>
        <form className="needs-validation was-validated">
          <div className="form-group">
            <input
              ref={(ref) => (this.otpInput = ref)}
              className={`form-control ${styles.username}`}
              name="otp"
              placeholder="Enter your one-time-passcode"
            />
          </div>
          <button
            style={{ width: '140px' }}
            className={styles.button}
            onClick={ e => this.onOTPEntered(e) }
            name="submit-otp"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    )
  }

  handleCloseModal = () => {
    localStorage.removeItem('loggedOutDueToInactivity')
    this.setState({ isModalOpen: false })
  }

  renderModal() {
    return (
      <dialog className={styles.loggedOutDialog} open>
        <h3>Please log in again.</h3>
        <p>Your authentication credentials have expired.</p>
        <button onClick={this.handleCloseModal}>Close</button>
      </dialog>
    )
  }

  renderLoginScreen() {
    const { history } = this.props
    if (this.otpToken() != null) {
      return this.renderOTP()
    } else {
      return (
        <div className={styles.login}>
          {this.renderError()}
          {this.renderInputs()}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '20px',
              alignItems: 'center'
            }}
          >
            <button
              style={{ width: '180px', }}
              type="button"
              className="btn btn-primary"
              onClick={() => history.push('/team/login')}
            >
              Team Login
            </button>
            <button
              style={{ width: '180px', }}
              type="button"
              className="btn btn-primary"
              onClick={() => history.push('/forgotPassword')}
            >
              Forgot Password
            </button>
            <button
              style={{ width: '180px', }}
              type="button"
              className="btn btn-primary"
              onClick={() => history.push('/privacyPolicy')}
            >
              Privacy Policy
            </button>
          </div>
        </div>
      )
    }

  }

  render() {
    const { isModalOpen } = this.state
    return (
      <div>
        {isModalOpen && this.renderModal()}
        {this.renderLoginScreen()}
      </div>
    )
  }
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  adminLogin: PropTypes.func.isRequired,
  loginError: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  error: selectUserError(state),
})

const mapDispatchToProps = {
  login,
  adminLogin,
  loginError,
  submitOTP,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
