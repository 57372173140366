import React from 'react'

export function userRoleComponent(filter, onChange) {
  return (
    <select
      onChange={event => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="">Show All</option>
      <option value="organization_administrator">
        Organization Administrator
      </option>
      <option value="user_administrator" name="User Administrator">
        User Administrator
      </option>
      <option value="regional_manager" name="Regional Manager">
        Regional Manager
      </option>
      <option value="store_associate" name="Store Associate">
        Store Associate
      </option>
      <option value="store_manager" name="Store Manager">
        Store Manager
      </option>
      <option value="third_party_associate" name="Third Party Associate">
        Third Party Associate
      </option>
      <option value="support_analyst" name="Store Analyst">
        Support Analyst
      </option>
      <option value="store_technician" name="Store Technician">
        Store Technician
      </option>
      <option value="key_user" name="Key User">
        Key User
      </option>
    </select>
  )
}
