/**
 * @module Sagas/User
 * @desc User
 */

import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  signIn,
  signOut,
  adminSignIn,
  getRegions,
  fwManagementSignIn,
  otpRequest,
} from '../../../api'
import { ActionTypes } from '../constants'
import { makeSelectToken, selectIsMTIUserFromRoles } from '../selectors'
import { restoreUser, saveUser, destroyUser } from '../localAuth'
import { resetOrm } from '../actions/orm'
import { loginSuccess } from '../actions/user'
import { fixApiRolesCache } from '../../../utils/mtiUtils'
import { storeImages, loadDataForOrganization } from '../actions'
import { errorToast } from '../../../utils/utils'

// const noPermissionText =
//   "You don't have appropriate rights to access Adming App.\nPlease contact your corporate manager."

/**
 * Login (Regular & OAuth)
 * @param payload
 */
function* login({ payload }) {
 const UserType = {
    CLIENT: 'client',
    ADMIN: 'admin'
  }

  try {
    let data, regions, organizations, userType

    if (payload.email && payload.password) {
      // Indicates regular sign in.
      data = yield call(signIn, payload)
      userType = UserType.CLIENT
    } else {
      // Otherwise, it's an OAuth sign in.
      // If 'organizations' exists, it's an OAuth client sign in. Else, OAuth admin sign in.
      data = payload
      userType = data.organizations ? UserType.CLIENT : UserType.ADMIN
    }

    const {
      authentications: [{ token, expiresAt, tokenId }],
      users: [
        { id,
          firstName,
          lastName,
          phoneNumber,
          employeeIdentifier,
          avatarId
        }
      ],
      images,
    } = data

    // Fetch regions and organizations for client users.
    if (userType === UserType.CLIENT) {
      try {
        regions = yield call(getRegions, token)
        organizations = data.organizations || (yield call(getOrganizations, token))
      } catch (error) {
        console.error(error)
        errorToast('Get regions failed')
      }
    }

    // Set up org, if available.
    const organization = organizations && organizations[0] ? organizations[0] : {}

    // Set up roles.
    const rolesCache = data.users[0].rolesCache || []
    rolesCache[0] = rolesCache[0] || {
      name: 'mti_admin',
      storeId: null,
      resourceId: -100,
      resourceType: 'MTI',
    }
    rolesCache[0].name = data.users[0].roleKey
    const roles = fixApiRolesCache(rolesCache)

    // Set up images, if available, and avatar.
    if (images && images.length > 0) {
      yield put(storeImages(images))
    }
    const avatar = images && images.find(i => i.id === avatar.Id)

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
      avatarId,
      avatar: userType === UserType.CLIENT ? avatar : null,
      phoneNumber: userType === UserType.CLIENT ? phoneNumber : null,
      regions: userType === UserType.CLIENT ? (regions || {}).regions : null,
      organizationId: userType === UserType.CLIENT ? organization.id : null,
      organizationName: userType === UserType.CLIENT ? organization.name : null,
      employeeIdentifier: userType === UserType.CLIENT ? employeeIdentifier : null,
    }

    saveUser(user)
    yield put(loginSuccess(user))
    yield put(loadDataForOrganization())
  } catch (err) {
    console.error(err)
    const res = err.response
    const responseBody = res ? (yield call([res, res.json])) : null
    destroyUser()
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: { message: 'Login failed, try again', body: responseBody },
    })
  }
}

/**
 * Admin login
 * @param payload
 */
function* adminLogin({ payload }) {
  try {
    const data = yield call(adminSignIn, payload)
    yield call(completeLogin, data)
  } catch (err) {
    yield call(loginError, err)
  }
}

/**
 * otp login completion
 * @param payload
 */
function* otpRequestSaga({ payload }) {
  try {
    const data = yield call(otpRequest, payload)
    yield call(completeLogin, data)
  } catch (err) {
    yield call(loginError, err)
  }
}

function* completeLogin(data) {
  try {
    const {
      authentications: [{ token, expiresAt, tokenId }],
      users: [
        {
          id,
          firstName,
          lastName,
          avatarId,
          roleKey,
        },
      ],
    } = data
    const rolesCache = data.users[0].rolesCache || []
    rolesCache[0] = rolesCache[0] || {
      name: 'mti_admin',
      storeId: null,
      resourceId: -100,
      resourceType: 'MTI',
    }
    rolesCache[0].name = data.users[0].roleKey
    const roles = fixApiRolesCache(rolesCache)

    // FW Management Authentication

    let saltAuthData = {}
    try {
      const authResult = yield call(fwManagementSignIn, token)
      saltAuthData = authResult.fwManagementAuthentications[0]
    } catch (e) { }

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      avatarId,
      avatar: null,
      saltToken: saltAuthData.token,
      saltTokenExpiresAt: saltAuthData.expiresAt,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
      roleKey,
    }

    saveUser(user)
    yield put(loginSuccess(user))
  } catch (err) {
    yield call(loginError, err)
  }
}

function* loginError(err) {
  console.error(err)
  const res = err.response
  const responseBody = res ? (yield call([res, res.json])) : null
  destroyUser()
  yield put({
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: { message: 'Login failed, try again', body: responseBody },
  })
}

/**
 * User Logout
 */
function* logout() {
  try {
    const token = yield select(makeSelectToken())
    if (token) {
      yield call(signOut, token)
    }
    yield put(resetOrm())
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    })
  } catch (err) {
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: 'Logout failed, try again',
    })
    errorToast('Logout failed')
  } finally {
    destroyUser()
  }
}

/**
 * Restore user auth object from local storage
 */
function* restoreUserAuth() {
  try {
    const user = restoreUser()
    if (user) {
      yield put({
        type: ActionTypes.USER_RESTORE_SUCCESS,
        payload: user,
      })
    } else {
      yield put({
        type: ActionTypes.USER_RESTORE_FAILURE,
      })
    }
    const { mtiRoles } = user || {}
    const isMtiUser = selectIsMTIUserFromRoles(mtiRoles)
    if (isMtiUser && window.location.pathname === '/') return

    yield put(loadDataForOrganization())
  } catch (e) {
    console.error(e)
    destroyUser()
    yield put({
      type: ActionTypes.USER_RESTORE_FAILURE,
    })
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_RESTORE_REQUEST, restoreUserAuth),
    takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
    takeLatest(ActionTypes.ADMIN_LOGIN_REQUEST, adminLogin),
    takeLatest(ActionTypes.OTP_REQUEST, otpRequestSaga),
    takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
  ])
}
