import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { login } from 'containers/App/actions'
import request from '../../../utils/request.js'
import Loading from "../../Loading.js"
import { errorToast } from "../../../utils/utils.js"

function AuthCodeExchange({ location, dispatch, history }) {
  const urlParams = new URLSearchParams(location.search)
  const code = urlParams.get('code')
  const state = urlParams.get('state')
  const error = urlParams.get('error')
  const teamKey = localStorage.getItem('team_key')

  useEffect(() => {
    postCode()
  }, [code, state])

  async function postCode() {
    if (!code) return
    const api = `${process.env.API_ROOT}/api`
    const clientId = process.env.CLIENT_ID

    try {
      const data = await request(`${api}/auth_code_exchange`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({code, state, team_key: teamKey, remote_client_id: clientId})
      })
      dispatch(login(data))
      history.push('/')
    } catch (error) {
      // For OAuth errors, e.g. someone signs in with admin credentials for a client team key.
      history.push('/team/login')
      errorToast('Invalid login. Please contact your administrator.')
    }
  }

  // For OIDC errors.
  if (error) {
    history.push('/team/login')
    errorToast('Invalid login. Please contact your administrator.')
    return null
  }

  return (
    <Loading />
  )
}

export default connect()(AuthCodeExchange)