import React, { useEffect, useRef } from 'react'

export default function TeamLoginScreen(props) {
  const inputRef = useRef(null)

  // Hide the nav for this screen.
  useEffect(() => {
    const nav = document.querySelector('[aria-label="breadcrumb"]')
    const originalDisplayValue = nav ? nav.style.display : ''
    if (nav) nav.style.display ='none'
    return () => {
      if (nav) nav.style.display = originalDisplayValue
    }
  }, [])

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  }

  function onSubmit(e) {
    e.preventDefault()
    const team = inputRef.current.value.trim()
    if (!team) {
      errorToast('Team name is empty')
      return
    } else {
      props.submit(team)
    }
  }

  return (
    <div className="col-5 mx-auto">
      <h1>Sign in with your team</h1>
      <label htmlFor="team-name">Enter your team name</label>
      <form
        onSubmit={onSubmit}
        style={formStyle}>
        <input
          type="text"
          ref={inputRef}
          defaultValue={props.initialTeamKey}
          className="form-control" />
        <button
          type="submit"
          className="btn btn-block btn-primary btn-raised">
            Continue
        </button>
      </form>
    </div>
  )
}