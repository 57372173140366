/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import styles from '../../styles/App.css'
// Import CSS reset and Global Styles
import GlobalStyles from '../../global-styles'

import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'

import {
  logout,
  restoreUser,
  notifyModal,
  openModal,
  closeModal,
  userDetailsAction,
} from './actions'
import { userIsAuthenticatedRedir, userIsNotAuthenticatedRedir } from './auth'
import { Routes } from './constants'

import Panel from './Panel'
import ModalContainer from '../ModalContainer'
import ToastContainer from '../../components/ToastContainer'
import LoginComponent from '../Login'
import TeamLogin from '../OAuth/TeamLogin/index'
import AuthCodeExchange from '../OAuth/AuthCodeExchange/index'
import HomeComponent from '../HomePage'
import PasswordSetComponent from '../PasswordSet'
import RegionsComponent from '../RegionsPage'
import StoresComponent from '../StoresPage'
import UsersComponent from '../UsersPage'
import SchedulesComponent from '../SchedulesPage'
import FloorsComponent from '../FloorsPage'
import FloorComponent from '../FloorPage'
import AreaComponent from '../AreaPage/index'
import FixtureComponent from '../FixturePage/index'
import OrganizationHomeComponent from '../HomePage/CorporateUser'
import KeysComponent from '../Keys'
import ReportsComponent from '../ReportsPage'
import ExceptionsComponent from '../ExceptionsPage'
import Breadcrumbs from '../../components/Breadcrumbs'
import TemplatesListComponent from '../Templates'
import TemplateFixtureComponent from '../TemplateFixture'
import TemplatePositionsComponent from '../TemplatePositions'
import OTAHomePageComponent from '../OTAHomePage'
import FirmwaresPageComponent from '../FirmwaresPage'
import HubsPageComponent from '../HubsPage'
import StatusPageComponent from '../StatusPage'
import SecuredProductsComponent from '../SecuredProducts'
import QRReaderComponent from '../QRReader'
import ForgotPasswordComponent from '../ForgotPassword'
import PrivacyPolicyComponent from '../PrivacyPolicy'
import IdleTimer from '../IdleTimer'
import LayoutTableViewPageComponent from '../LayoutTableViewPage'
import OrphanedDevicesPageComponent from '../OrphanedDevicesPage'
import StoresDashboardComponent from '../StoresDashboard'
import IOTDashboardComponent from '../IOTDashboard'

import { makeSelectData, makeSelectIsLoading } from './selectors'
import appReducer from './appReducer'
import userReducer from './reducer'
import modalReducer from './modalReducer'
import tagsReducer from './tagsReducer'
import organizationReducer from './organizationReducer'
import organizationEditReducer from './organizationEditReducer'
import keysReducer from './keysReducer'
import userEditReducer from './userEditReducer'
import scheduleEditReducer from './scheduleEditReducer'
import regionEditReducer from './regionEditReducer'
import storeEditReducer from './storeEditReducer'
import firmwaresReducer from './reducers/firmwaresReducer'
import hubsReducer from './reducers/hubsReducer'
import securedProductEditReducer from './reducers/securedProductEditReducer'
import appSaga from './sagas/appSaga'
import authSaga from './sagas/authSaga'
import mqttSaga from './sagas/mqttSaga'
import ormSaga from './sagas/ormSaga'
import tagsSaga from './sagas/tagsSaga'
import organizationSaga from './sagas/organizationSaga'
import positionSaga from './sagas/positionSaga'
import storeSaga from './sagas/storeSaga'
import keysSaga from './sagas/keysSaga'
import regionEditSaga from './sagas/regionEditSaga'
import userEditSaga from './sagas/userEditSaga'
import scheduleEditSaga from './sagas/scheduleEditSaga'
import storeEditSaga from './sagas/storeEditSaga'
import firmwaresSaga from './sagas/firmwaresSaga'
import otauHubSaga from './sagas/otauHubSaga'
import securedProductEditSaga from './sagas/securedProductEditSaga'

import { DeviceConfiguration } from '../../components/DeviceConfiguration'
import { AssignableRole } from '../../components/AssignableRoles'
import hubSaga from '../../components/Provisioning/hubSaga'
import hubReducer from '../../components/Provisioning/reducers'

import packageData from '../../../package.json'
import UserManagement from '../UsersPage/userManagement'
import {
  onLogin,
  onLogout,
  logoutUser,
  handleBeforeunload,
  handleStorageEvent,
  syncLogin,
  syncLogout,
} from '../../components/Crosstab'
import { Overlay } from '../../styles/styled'
import ProgressIndicator from '../../components/ProgressIndicator'
import Drawer from '../../components/Drawer'
import OrganizationManagement from '../OrganizationPage/organizationManagement'
import StoreManagement from '../StoresPage/storeManagement'

import { ReactTableDefaults } from 'react-table'

// global react table configs
Object.assign(ReactTableDefaults, {
  onFilteredChange: () => {
    const nodes = document.getElementsByClassName('rt-tbody')
    if (nodes.length) {
      // scroll to top on filter data
      nodes[0].scrollTop = 0
    }
  },
})

// Need to apply the hocs here to avoid applying them inside the render method
const Home = userIsAuthenticatedRedir(HomeComponent)
const Login = userIsNotAuthenticatedRedir(LoginComponent)
const PasswordSet = PasswordSetComponent
const Regions = userIsAuthenticatedRedir(RegionsComponent)
const Stores = userIsAuthenticatedRedir(StoresComponent)
const Floors = userIsAuthenticatedRedir(FloorsComponent)
const Floor = userIsAuthenticatedRedir(FloorComponent)
const Users = userIsAuthenticatedRedir(UsersComponent)
const Schedules = userIsAuthenticatedRedir(SchedulesComponent)
const Area = userIsAuthenticatedRedir(AreaComponent)
const Fixture = userIsAuthenticatedRedir(FixtureComponent)
const Keys = userIsAuthenticatedRedir(KeysComponent)
const OrganizationHomePage = userIsAuthenticatedRedir(OrganizationHomeComponent)
const Reports = userIsAuthenticatedRedir(ReportsComponent)
const Exceptions = userIsAuthenticatedRedir(ExceptionsComponent)
const TemplatesList = userIsAuthenticatedRedir(TemplatesListComponent)
const TemplateFixture = userIsAuthenticatedRedir(TemplateFixtureComponent)
const TemplatePositions = userIsAuthenticatedRedir(TemplatePositionsComponent)
const FirmwaresPage = userIsAuthenticatedRedir(FirmwaresPageComponent)
const HubsPage = userIsAuthenticatedRedir(HubsPageComponent)
const StatusPage = userIsAuthenticatedRedir(StatusPageComponent)
const OTAHomePage = userIsAuthenticatedRedir(OTAHomePageComponent)
const SecuredProductsPage = userIsAuthenticatedRedir(SecuredProductsComponent)
const QRReaderPage = userIsAuthenticatedRedir(QRReaderComponent)
const LayoutTableViewPage = userIsAuthenticatedRedir(LayoutTableViewPageComponent)
const OrphanedDevicesPage = userIsAuthenticatedRedir(OrphanedDevicesPageComponent)
const StoresDashboardPage = userIsAuthenticatedRedir(StoresDashboardComponent)
const IOTDashboard = userIsAuthenticatedRedir(IOTDashboardComponent)

const inDevelopment = 'This feature is still in development'

class App extends React.Component {
  constructor(...args) {
    super(...args)
    this.props.restoreAuth()
    if (logoutUser()) {
      this.logout()
    }
  }

  componentDidUpdate() {
    if (this.props.user && this.props.user.id) {
      syncLogin()
    }
  }

  componentDidMount() {
    window.addEventListener('storage', handleStorageEvent)
    window.addEventListener('beforeunload', handleBeforeunload)
    onLogin(() => window.location.reload())
    onLogout(() => this.props.logout())
  }

  componentWillUnmount() {
    window.removeEventListener('storage', handleStorageEvent)
    window.removeEventListener('beforeunload', handleBeforeunload)
  }

  logout = () => {
    syncLogout()
    this.props.logout()
  }

  openUserDetailsModal = () => {
    const { user, userDetailsAction } = this.props
    userDetailsAction(user)
  }

  render() {
    const {
      user,
      notifyModal: doNotifyModal,
      openModal: doOpenModal,
    } = this.props
    const { HEROKU_RELEASE_VERSION, GIT_HASH } = process.env
    const { version } = packageData
    const { isLoading } = user || {}

    return (
      <div className={styles.App}>
        <Router>
          <div className={styles.AppContent}>
            <div className="bmd-layout-container bmd-drawer-f-l bmd-drawer-overlay">
              {user.id && <Drawer />}
              <Switch>
                <Route exact path="/passwords/edit" component={PasswordSet} />
                <Route
                  path="*"
                  render={props => (
                    <React.Fragment>
                      <IdleTimer user={user} />
                      <Panel
                        {...props}
                        user={user}
                             doLogout={this.logout}
                             onShowUserDetails={this.openUserDetailsModal}
                             doNotifyModal={() => doNotifyModal(inDevelopment)}
                             onShowSupportModal={() =>
                               doOpenModal({
                                 id: 'support',
                                 type: 'bootstrap',
                                 content: (
                                   <div className="modal-content">
                                     <div className="modal-body">
                                       <div className="container">
                                         <div className="row">
                                           {version
                                            ? `App Version: ${version}`
                                            : null}
                                         </div>
                                         <div className="row">
                                           {HEROKU_RELEASE_VERSION
                                            ? `Build Number: ${HEROKU_RELEASE_VERSION}`
                                            : null}
                                         </div>
                                         <div className="row">
                                           {GIT_HASH ? `Hash: ${GIT_HASH}` : null}
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 ),
                               })
                             }
                      />
                      <Route component={StoreManagement} />
                      <Breadcrumbs />
                    </React.Fragment>
                  )}
                />
              </Switch>
              <div className={styles.content}>
                <Route exact path="/" component={Home} />
                <Route path={Routes.LOGIN} component={Login} />
                <Route path={Routes.ADMIN_LOGIN} component={Login} />
                <Route path='/team/login/:teamKey?' component={TeamLogin} />
                <Route path='/auth/callback' component={AuthCodeExchange} />
                <Route exact path="/passwordSet" component={PasswordSet} />
                <Route
                  path="/forgotPassword"
                  component={ForgotPasswordComponent}
                />
                <Route
                  exact
                  path="/privacyPolicy"
                  component={PrivacyPolicyComponent}
                />
                <Route exact path="/admin/ota" component={OTAHomePage} />
                <Route path="/admin/ota/firmwares" component={FirmwaresPage} />
                <Route path="/admin/ota/hubs" component={HubsPage} />
                <Route path="/admin/ota/status" component={StatusPage} />
                <Route path="/stores/:sId/:sName/keys" component={Keys} />
                <Route
                  path="/organizations/:oId/:oName/stores/:sId/:sName/keys"
                  component={Keys}
                />
                <Route exact path="/regions" component={Regions} />
                <Route
                  exact
                  path="/organizations/:oId/:oName/regions"
                  component={Regions}
                />
                <Route path="/historical_logs" component={Reports} />
                <Route path="/current_exceptions" component={Exceptions} />
                <Route
                  path="/organizations/:oId/:oName/historical_logs"
                  component={Reports}
                />
                <Route
                  path="/organizations/:oId/:oName/current_exceptions"
                  component={Exceptions}
                />
                <Route path="/qr_reader" component={QRReaderPage} />
                <Route
                  exact
                  path="/organizations/:oId/:oName"
                  component={OrganizationHomePage}
                />
                <Route exact path="/stores" component={Stores} />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores"
                  component={Stores}
                />
                {/* Store Layout Admin */}
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/floors"
                  component={Floors}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/floors/:fId/:fName"
                  component={Floor}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName"
                  component={Area}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName/fixtures/:fxId/:fxName"
                  component={Fixture}
                />
                {/* Store Layout */}
                <Route
                  exact
                  path="/stores/:sId/:sName/floors"
                  component={Floors}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/floors/:fId/:fName"
                  component={Floor}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName"
                  component={Area}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName/fixtures/:fxId/:fxName"
                  component={Fixture}
                />
                {/* Users */}
                <Route exact path="/users" component={Users} />
                <Route
                  exact
                  path="/organizations/:oId/:oName/users"
                  component={Users}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/users"
                  component={Users}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/secured_products"
                  component={SecuredProductsPage}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/secured_products"
                  component={SecuredProductsPage}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/schedules"
                  component={Schedules}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/iot_dashboard"
                  component={IOTDashboard}
                />
                <Route
                  exact
                  path="/iot_dashboard"
                  component={IOTDashboard}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/layout_table_view"
                  component={LayoutTableViewPage}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/layout_table_view"
                  component={LayoutTableViewPage}
                />
                <Route
                  exact
                  path="/stores/:sId/:sName/orphaned_devices"
                  component={OrphanedDevicesPage}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/orphaned_devices"
                  component={OrphanedDevicesPage}
                />
                <Route
                  exact
                  path="/stores/dashboard"
                  component={StoresDashboardPage}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/dashboard"
                  component={StoresDashboardPage}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/stores/:sId/:sName/schedules"
                  component={Schedules}
                />
                <Route exact path="/templates" component={TemplatesList} />
                <Route
                  exact
                  path="/organizations/:oId/:oName/templates"
                  component={TemplatesList}
                />
                <Route
                  exact
                  path="/templates/create"
                  component={TemplateFixture}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/templates/create"
                  component={TemplateFixture}
                />
                <Route
                  exact
                  path="/templates/:tId/:tName/positions"
                  component={TemplatePositions}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/templates/:tId/:tName/positions"
                  component={TemplatePositions}
                />
                <Route
                  exact
                  path="/templates/:tId/:tName"
                  component={TemplateFixture}
                />
                <Route
                  exact
                  path="/organizations/:oId/:oName/templates/:tId/:tName"
                  component={TemplateFixture}
                />
                {isLoading && (
                   <React.Fragment>
                     <Overlay />
                     <ProgressIndicator />
                   </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </Router>
        <ModalContainer />
        <GlobalStyles />
        <UserManagement />
        <OrganizationManagement />
        <ToastContainer />
      </div>
    )
  }
}

App.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
  restoreAuth: PropTypes.func,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
}

export function mapDispatchToProps(dispatch) {
  return {
    logout: e => {
      if (e !== undefined && e.preventDefault) e.preventDefault()
      dispatch(logout())
    },
    restoreAuth: () => dispatch(restoreUser()),
    notifyModal: m => dispatch(notifyModal(m)),
    openModal: m => dispatch(openModal(m)),
    closeModal: m => dispatch(closeModal(m)),
    userDetailsAction: m => dispatch(userDetailsAction(m)),
  }
}

const mapStateToProps = createSelector(
  makeSelectData(),
  makeSelectIsLoading(),
  (data, isLoading) => ({
    user: { ...data, isLoading },
  })
)

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withAppReducer = injectReducer({ key: 'app', reducer: appReducer })
const withUserReducer = injectReducer({ key: 'user', reducer: userReducer })
const withModalReducer = injectReducer({ key: 'modal', reducer: modalReducer })

const withTagsReducer = injectReducer({
  key: 'tags',
  reducer: tagsReducer,
})
const withOrganizationReducer = injectReducer({
  key: 'organization',
  reducer: organizationReducer,
})
const withOrganizationEditReducer = injectReducer({
  key: 'organizationEdit',
  reducer: organizationEditReducer,
})
const withkeysReducer = injectReducer({
  key: 'keys',
  reducer: keysReducer,
})
const withUserEditReducer = injectReducer({
  key: 'userEdit',
  reducer: userEditReducer,
})
const withScheduleEditReducer = injectReducer({
  key: 'scheduleEdit',
  reducer: scheduleEditReducer,
})
const withRegionEditReducer = injectReducer({
  key: 'regionEdit',
  reducer: regionEditReducer,
})
const withStoreEditReducer = injectReducer({
  key: 'storeEdit',
  reducer: storeEditReducer,
})
const withFirmwaresReducer = injectReducer({
  key: 'firmwaresReducer',
  reducer: firmwaresReducer,
})
const withHubsReducer = injectReducer({
  key: 'hubsReducer',
  reducer: hubsReducer,
})
const withSecuredProductEditReducer = injectReducer({
  key: 'securedProductEdit',
  reducer: securedProductEditReducer,
})
const withAppSaga = injectSaga({ key: 'app', saga: appSaga })
const withAuthSaga = injectSaga({ key: 'user', saga: authSaga })
const withMqttSaga = injectSaga({ key: 'mqtt', saga: mqttSaga })
const withOrmSaga = injectSaga({ key: 'orm', saga: ormSaga })
const withTagsSaga = injectSaga({
  key: 'tags',
  saga: tagsSaga,
})
const withOrganizationSaga = injectSaga({
  key: 'organization',
  saga: organizationSaga,
})
const withPositionSaga = injectSaga({
  key: 'position',
  saga: positionSaga,
})
const withStoreSaga = injectSaga({
  key: 'store',
  saga: storeSaga,
})
const withkeysSaga = injectSaga({
  key: 'keys',
  saga: keysSaga,
})
const withUserEditSaga = injectSaga({
  key: 'userEdit',
  saga: userEditSaga,
})
const withScheduleEditSaga = injectSaga({
  key: 'scheduleEdit',
  saga: scheduleEditSaga,
})
const withRegionEditSaga = injectSaga({
  key: 'regionEdit',
  saga: regionEditSaga,
})
const withStoreEditSaga = injectSaga({
  key: 'storeEdit',
  saga: storeEditSaga,
})
const withFirmwaresSaga = injectSaga({
  key: 'firmwaresReducer',
  saga: firmwaresSaga,
})
const withOtauHubSaga = injectSaga({
  key: 'hubsReducer',
  saga: otauHubSaga,
})
const withSecuredProductEditSaga = injectSaga({
  key: 'securedProductEdit',
  saga: securedProductEditSaga,
})
const withDeviceConfigurationsSaga = injectSaga({
  key: 'deviceConfigurations',
  saga: DeviceConfiguration.saga,
})
const withDeviceConfigurationsReducer = injectReducer({
  key: 'deviceConfigurations',
  reducer: DeviceConfiguration.reducer,
})
const withAssignableRolesSaga = injectSaga({
  key: 'assignableRoles',
  saga: AssignableRole.saga,
})
const withAssignableRolesReducer = injectReducer({
  key: 'assignableRoles',
  reducer: AssignableRole.reducer,
})
const withHubSaga = injectSaga({
  key: 'hubs',
  saga: hubSaga,
})
const withhubSagaReducer = injectReducer({
  key: 'hubs',
  reducer: hubReducer,
})

export default compose(
  withAppReducer,
  withModalReducer,
  withUserReducer,
  withTagsReducer,
  // withRegionsReducer,
  withOrganizationReducer,
  withOrganizationEditReducer,
  withkeysReducer,
  withRegionEditReducer,
  withUserEditReducer,
  withScheduleEditReducer,
  withStoreEditReducer,
  withFirmwaresReducer,
  withHubsReducer,
  withSecuredProductEditReducer,
  withAppSaga,
  withAuthSaga,
  withMqttSaga,
  withOrmSaga,
  withTagsSaga,
  withScheduleEditSaga,
  withOrganizationSaga,
  withPositionSaga,
  withStoreSaga,
  withkeysSaga,
  withRegionEditSaga,
  withUserEditSaga,
  withStoreEditSaga,
  withFirmwaresSaga,
  withOtauHubSaga,
  withSecuredProductEditSaga,
  withDeviceConfigurationsSaga,
  withDeviceConfigurationsReducer,
  withAssignableRolesSaga,
  withAssignableRolesReducer,
  withConnect,
  withHubSaga,
  withhubSagaReducer
)(App)
