import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import TeamLoginScreen from './screen.js'
import { errorToast } from '../../../utils/utils.js'
import request from '../../../utils/request.js'

function TeamLogin({ history, match }) {
  const { teamKeyFromUrl } = match.params
  const teamKeyFromStorage = localStorage.getItem('team_key')
  const initialTeamKey = teamKeyFromUrl || teamKeyFromStorage || ''

  // Redirect if user exists.
  useEffect(() => {
    const user = localStorage.getItem('__user')
    if (user) history.push('/')
  }, [])

  async function submit(team) {
    const api = `${process.env.API_ROOT}/api`
    const clientId = process.env.CLIENT_ID

    try {
      const response = await request(`${api}/oauth_provider`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;'},
        body: JSON.stringify({team_key: team, remote_client_id: clientId})
      })

      localStorage.setItem('team_key', team)
      window.location.href = response.auth_url
    } catch (e) {
      console.error(e)
      errorToast('Invalid team')
    }
  }

  return (
    <TeamLoginScreen
      initialTeamKey={initialTeamKey}
      submit={submit}
    />
  )
}

export default withRouter(TeamLogin)