// Data handling
import AssignableRole from './model'
import { Seq, Set, List } from 'immutable'

// Copy
import { FormattedMessage } from 'react-intl'

// Component building
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  assignableRolesSelector,
  selectIsUserAdmin,
} from '../../containers/App/selectors'
import { FormRow, FormGroup } from '../Bootstrap'
import Select, { components } from 'react-select'
import HelpIcon from '../HelpIcon'

const intlPath = strings => `components.assignableRoles.${strings.join('.')}`

const RemoveManaged = props => {
  if (props.data.managed) {
    return <components.MultiValueRemove {...props} />
  }
  return <React.Fragment />
}

/**
 * Displays a selector for stores that the current user managed.
 *
 * Example:
 *   import { Control: AssignableRoleControl } from '../../components/AssignableRole'
 *   constructor(props) {
 *     super(props)
 *     this.handleChange = this.handleChange.bind(this)
 *   }
 *
 *   handleChange({persona, resourceIds}) {
 *     this.setState({persona, resourceIds})
 *   }
 *
 *   render() {
 *     const { storeId } = this.state
 *     return (
 *        <div className="form-group">
 *          <label>Choose a Managed Store</label>
 *          <AssignableRoleControl persona={persona} resourceIds={resourceIds} onChange={this.handleChange} />
 *        </div>
 *     )
 *   }
 *
 * Because this component involves two, paired controls with interactive
 * behavior, it can't be a pure component.
 */
class Control extends React.Component {
  constructor(props) {
    super(props)

    this.handlePersonaChange = this.handlePersonaChange.bind(this)
    this.handleResourceChange = this.handleResourceChange.bind(this)

    const { persona, resourceIds, isKeyUser } = props

    const ridSet = Set(resourceIds || [])
    const initProps = {
      persona,
      resourceIds: ridSet,
      isKeyUser: isKeyUser || false
    }
    this.state = initProps
  }

  canChangePersona() {
    return this.unmanagedResourceIds().isEmpty()
  }

  /**
   * Whether to render read-only controls.
   */
  isReadOnly() {
    return typeof this.props.onChange !== 'function'
  }

  /** The user might have roles in some resources that the current user
   * cannot manage. It's important that we keep track of these, because
   * we're not allowed to delete them.
   */
  unmanagedResourceIds() {
    const { list, resourceIds, persona } = this.props
    const found = list.find(p => p.key === persona)
    if (!found) return Set(resourceIds) // They're all unmanaged

    const managed = Seq(found.resources)
      .map(r => r.id)
      .toSet()
    return Set(resourceIds).subtract(managed)
  }

  /** On load (or on the return from the API call) if there's only
   * one persona available, choose it as the default.
   * Also, if "Store Associate" is an option, choose it.
   * Not just in the UI, but send the data up the hierarchy
   * for the form.
   */
  setDefaultPersona() {
    const { list } = this.props
    const { persona } = this.state

    // We're setting defaults. Don't do anything if a choice has
    // been made.
    if (typeof persona !== 'undefined') return

    // If the list is uninitialized (or if the user has no ability
    // to assign roles) bail now.
    if (list.isEmpty()) return

    // If there's only one role, select it.
    if (list.size === 1) {
      // The logic is the same as in the event handler. Gin up a fake
      // event to keep things DRY.
      this.handlePersonaChange({ value: list.first().key })
      return
    }

    // If 'store_associate' is not an option, bail.
    if (!list.some(i => i.key === 'store_associate')) return

    // If 'store_associate' is an option, default to it.
    this.handlePersonaChange({ value: 'store_associate' })
  }

  /** Send the action to load the current logged-in user's assignable
   * roles from the API. Component will display a "loading..." text,
   * and re-render when the API call has finished.
   * If the data have already been loaded from a previous call,
   * don't do the API call again, but select the default option if one
   * makes sense.
   */
  componentDidMount() {
    // This is unlikely to change during a session.
    if (this.props.list.isEmpty()) {
      this.props.loadAssignableRoles()
    } else {
      this.setDefaultPersona()
    }
  }

  /** Handle re-rendering when the API call is done.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isKeyUser !== this.props.isKeyUser) {
      this.setState({ isKeyUser: this.props.isKeyUser })
      if (this.props.isKeyUser) {
        this.handlePersonaChange({ value: 'key_user' })
      }
    }

    this.setDefaultPersona()
  }

  /**
   * Sends changes up to the containing component
   */
  bubbleUpChanges() {
    if (this.isReadOnly()) return

    const { persona, resourceIds } = this.state
    this.props.onChange({ persona, resourceIds: resourceIds })
  }

  /** We need to clear out the resource if the persona changes, because
   * each persona has its own list of valid resources. Even if the type
   * might be the same between two personae (e.g. store manager and store
   * associate) the actual list might be different.
   */
  handlePersonaChange(value) {
    if (!this.canChangePersona()) return
    const persona = value.value

    // We're going to cause some side effects, so it's worth making
    // sure that something has _actually_ changed.
    if (persona === this.state.persona) return

    const { list } = this.props
    const assoc = list.find(ar => ar.key === persona)

    // Since we changed the persona, clobber the resourceIds.
    // If there is only one resource, use it.
    // Otherwise, blank it out. */
    const resourceIds =
      typeof assoc === 'undefined'
        ? Set()
        : assoc.resources.size === 1 ? Set([assoc.resources.first().id]) : Set()

    this.setState({ persona, resourceIds }, () => this.bubbleUpChanges())
  }

  /** Simply set the object in the state and call up the hierarchy
   * with the change event.
   *
   * @param values should be an array like
   *   [
   *     { value: 1, label: "Kanto Store" },
   *     { value: 2, label: "Johto Store" }
   *   ]
   */
  handleResourceChange(values) {
    const resourceIds = Set(values.map(v => v.value))

    // These may not be removed.
    const unmanaged = this.unmanagedResourceIds()

    this.setState(
      {
        resourceIds: unmanaged.union(resourceIds),
      },
      () => this.bubbleUpChanges()
    )
  }

  /** Renders a Bootstrap form control with a read-only value. */
  renderReadOnly(id, values) {
    return (
      <div className="assignable-roles-read-only">
        <FormattedMessage id={id} values={values} />
      </div>
    )
  }

  renderReadOnlyList(values) {
    const labels = values.map(value => (
      <span key={value.value}>{value.label}</span>
    ))
    return <div className="assignable-roles-read-only-list">{labels}</div>
  }

  /** Render the "Loading" text. */
  renderPending = () => this.renderReadOnly(intlPath`loading`)

  /** Render the "no results" text. */
  renderEmpty = () => this.renderReadOnly(intlPath`empty`)

  /** Render the API error, and a button to try again. Who knows,
   * maybe it'll work a second time. And anyway, give the user a
   * toy to play with.
   */
  renderError() {
    const { message, loadAssignableRoles } = this.props
    return (
      <span className="text-danger">
        <FormattedMessage id="failed" values={{ message }} />
        <a href="#" onClick={loadAssignableRoles}>
          <FormattedMessage id={intlPath`reload`} />
        </a>
      </span>
    )
  }

  /** Renders the list of available roles, or the name of the single role
   * if only one is available.
   */
  personaControl() {
    const { persona, isKeyUser } = this.state
    const { list, isUserAdmin } = this.props
    if (list.size === 1) {
      return this.renderReadOnly(intlPath`personaName`, list.first().toJSON())
    }
    if (!persona || typeof persona === 'undefined') {
      return (<React.Fragment>Unavailable</React.Fragment>)
    }
    if (this.isReadOnly() || !this.canChangePersona()) {
      return (
        <React.Fragment>
          {this.isReadOnly() || (
            <HelpIcon helpKey="assignableRoles.unmanagedRoles" />
          )}
          {this.renderReadOnly(
            intlPath`personaName`,
            list.find(p => p.key == persona).toJSON()
          )}
        </React.Fragment>
      )
    }

    // We remove the Org Admin role as an option for user admins.
    // This is a temporary fix until we can alter the backend.
    let options = list
      .filter(role => {
        // Exclude org admin role as an option for user admins.
        if (role.key === 'organization_administrator' && isUserAdmin) return false
        // Exclude key user role as an option unless isKeyUser checkbox is marked.
        if (role.key === 'key_user' && !isKeyUser) return false
        return true
        })
      .map(role => {
        return { value: role.key, label: role.name }
      })

    // If the isKeyUser checkbox is marked, filter all other roles out.
    if (isKeyUser) {
      options = options.filter(opt => opt.value === 'key_user')
    }

    const value = options.find(opt => opt.value === persona)

    return (
      <Select
        options={options}
        required
        id="persona"
        value={value}
        onChange={this.handlePersonaChange}
      />
    )
  }

  resourceValues() {
    const { persona, resourceIds } = this.state
    const { roleResources, list } = this.props

    const managedResources = list.find(p => p.key === persona).resources

    return Seq(resourceIds)
      .map(rid => {
        const managed = managedResources.find(mr => mr.id === rid)
        if (managed) {
          return {
            value: rid,
            label: managed.name,
            managed: true,
          }
        }
        const unmanaged = roleResources.find(rr => rr.id === rid)
        return {
          value: rid,
          label: unmanaged.name,
          managed: false,
        }
      })
      .sort((l, r) => {
        if (l.managed !== r.managed) {
          return l.managed ? 1 : -1 // sort unmanaged before managed
        }
        if (l.name < r.name) return -1
        if (l.name > r.name) return 1
        return 0
      })
  }

  /**
   * If a persona has been chosen, render the list of "locations" or the singular
   * location if only one is available.
   */
  resourceControl() {
    const { persona } = this.state
    if (typeof persona === 'undefined')
      return this.renderReadOnly(intlPath`resourceNoPersona`)

    const { list } = this.props
    const assoc = list.find(role => role.key === persona)
    if (typeof assoc === 'undefined') {
      return this.renderReadOnly(intlPath`resourceNoPersona`)
    }

    const values = this.resourceValues()
    if (this.isReadOnly()) return this.renderReadOnlyList(values)

    if (
      assoc.resources.size === 1 &&
      values.size === 1 &&
      assoc.resources.first().id === values.first().id
    )
      return this.renderReadOnlyList(values)

    const options = assoc.resources.map(resource => ({
      value: resource.id,
      label: resource.name,
    }))

    return (
      <Select
        options={options.toJS()}
        isMulti
        id="resources"
        value={values.toJS()}
        onChange={this.handleResourceChange}
        components={{ MultiValueRemove: RemoveManaged }}
        isRequired
      />
    )
  }

  /**
   * Render a row with a group of paired controls and their default error text.
   */
  renderControls() {
    return (
      <FormRow>
        <FormGroup className="col-md-6">
          <label htmlFor="persona">
            <FormattedMessage id={intlPath`persona`} /> *
          </label>
          {this.personaControl()}
          <div className="invalid-feedback">
            <FormattedMessage id={intlPath`personaInvalid`} />
          </div>
        </FormGroup>
        <FormGroup className="col-md-6">
          <label htmlFor="resource">
            <FormattedMessage id={intlPath`resource`} /> *
          </label>
          {this.resourceControl()}
          <div className="invalid-feedback">
            <FormattedMessage id={intlPath`resourceInvalid`} />
          </div>
        </FormGroup>
      </FormRow>
    )
  }

  /**
   * Decide what to render based on the status of the API call.
   */
  render() {
    const { status } = this.props
    switch (status) {
      case 'PENDING':
        return this.renderPending()
      case 'FAILED':
        return this.renderError()
    }
    return this.renderControls()
  }
}

Control.propTypes = {
  onChange: PropTypes.func,
  persona: PropTypes.string,
  resourceIds: PropTypes.array,
  roleResources: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.instanceOf(List),
  ]),
  isKeyUser: PropTypes.bool,
}

const mapStateToProps = state => ({
  ...assignableRolesSelector(state),
  isUserAdmin: selectIsUserAdmin(state),
})

const mapDispatchToProps = {
  loadAssignableRoles: AssignableRole.actionMakers.load,
}

export default connect(mapStateToProps, mapDispatchToProps)(Control)
