import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import placeholder from 'images/icon-user-placeholder.png'
import EditDisabled from '../../../components/EditDisabled'
import AvatarPicker from '../../../components/AvatarPicker'
import AspectRatioTooltip from '../../../components/AspectRatioTooltip'
import { emailValidationPattern } from '../../../utils/utils'
import SubmitWithHint, {
  defaultRequiredMessage,
} from '../../../components/SubmitWithHint'
import { Overlay } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'
import {
  setServerError,
  setServerErrorDismiss,
} from '../../../utils/validation'
import { Control as AssignableRolesControl } from '../../../components/AssignableRoles'

export class CreateEditUserScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCreateNewUser: true,
      showValidationHint: false,
      serverValidationFailed: false,
      userEditLoading: props.userEditLoading,
      isKeyUser: false,
    }
  }

  setLoading(loading) {
    this.setState({ userEditLoading: loading })
  }

  componentWillUnmount() {
    const inputRefs = [
      'password',
      'email',
      'firstName',
      'lastName',
      'passcode',
      'employeeIdentifier',
      // 'welcomeEmail',
      'phoneNumber',
      'avatar',
    ]
    inputRefs.forEach(input => {
      const element = this[input]
      if (element && !element.isCustom) {
        $(element).popover('dispose') // eslint-disable-line
      }
    })
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.email) {
      const msg = validationErrors.email[0]
      const element = this.email
      setServerError(element, msg)
    }
    if (validationErrors.firstName) {
      const msg = validationErrors.firstName[0]
      const element = this.firstName
      setServerError(element, msg)
    }
    if (validationErrors.lastName) {
      const msg = validationErrors.lastName[0]
      const element = this.lastName
      setServerError(element, msg)
    }
    if (validationErrors.employeeIdentifier) {
      const msg = validationErrors.employeeIdentifier[0]
      const element = this.employeeIdentifier
      setServerError(element, msg)
    }
    if (validationErrors.phoneNumber) {
      const msg = validationErrors.phoneNumber[0]
      const element = this.phoneNumber
      setServerError(element, msg)
    }
    if (validationErrors.avatar) {
      const msg = validationErrors.avatar[0]
      const element = this.avatar
      setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setState({ serverValidationFailed: true })
        this.setValidationErrors(validationErrors)
        if (validationErrors.email) {
          const msg = validationErrors.email[0]
          const element = this.email
          setServerError(element, msg)
        }
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  /**
   * Client side validation for custom components
   *
   * @returns {boolean}
   */
  isValid = () => {
    if (this.formRef.checkValidity()) {
      this.setState({ showValidationHint: false })
      return true
    } else {
      this.formRef.reportValidity()
      this.setState({ showValidationHint: true })
      return false
    }
  }

  highlightInvalid = () => { }

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  renderFieldsCreateEditUser = () => {
    const {
      onChange,
      user,
      isEditProfileOnly,
      hasRegionalManagerRole,
      hasStoreManagerRole,
      hasOrganizationAdminRole,
      hasMtiAdminRole,
    } = this.props
    const { userEditLoading, isKeyUser } = this.state
    const {
      id,
      firstName,
      lastName,
      email,
      employeeIdentifier,
      phoneNumber,
      avatarUrl,
      persona,
      roleKey,
      resourceIds,
      roleResources,
    } = user
    const isEdit = !!email || email === null

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <AspectRatioTooltip
              ratioText="1:1"
              ratio={1}
              render={ratioTooltip => (
                <AvatarPicker
                  ref={ref => {
                    this.avatar = ref
                    setServerErrorDismiss(ref)
                  }}
                  defaultValue={avatarUrl}
                  onChange={({ avatarUrl, avatar }) =>
                    onChange({ avatarUrl, avatar })
                  }
                  ratioTooltip={ratioTooltip}
                />
              )}
            />
          </div>
          <div className="col-md-9">
            { !isEdit &&
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="h6" htmlFor="key_user">Key User (Unlock Only)</label>
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    id="key_user"
                    checked={isKeyUser}
                    onChange={e => this.setState({ isKeyUser: e.target.checked })}
                  />
                  {isKeyUser &&
                    <p className="small">As a key user, this user will not be able to sign in.<br />They can only activate and use their key card.</p>
                  }
                </div>
              </div>
            }
            <div className="form-row">
              <div className="form-group col-md-6 bmd-form-group mb-0">
                <label htmlFor="firstName">First name *</label>
                <input
                  ref={ref => {
                    this.firstName = ref
                    setServerErrorDismiss(ref)
                  }}
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder=""
                  required="required"
                  defaultValue={firstName}
                  onChange={e => onChange({ firstName: e.target.value })}
                  onInvalid={defaultRequiredMessage}
                  onInput={defaultRequiredMessage}
                />
                <div className="invalid-feedback">
                  Please enter a valid first name.
                </div>
              </div>
              <div className="form-group col-md-6 bmd-form-group mb-0">
                <label htmlFor="lastName">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder=""
                  defaultValue={lastName}
                  onChange={e => onChange({ lastName: e.target.value })}
                  onInvalid={defaultRequiredMessage}
                  onInput={defaultRequiredMessage}
                />
              </div>
            </div>
            <div className="form-row">
              {/* Hide email field for creating/editing key users */}
              {(isEdit && roleKey !== 'key_user') || (!isEdit && !isKeyUser) && (
                <div className="form-group col-md-6 bmd-form-group mb-0">
                  <label htmlFor="email">Email *</label>
                  <input
                    ref={ref => {
                      this.email = ref
                      setServerErrorDismiss(ref)
                    }}
                    type="email"
                    className="form-control"
                    id="email"
                    required={!isKeyUser}
                    pattern={emailValidationPattern}
                    onKeyUp={() =>
                      (this.email.value = this.email.value.toLowerCase())
                    }
                    placeholder=""
                    defaultValue={email}
                    onChange={e => onChange({ email: e.target.value })}
                    onInvalid={defaultRequiredMessage}
                    onInput={defaultRequiredMessage}
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                </div>
              )}
              {isEdit && roleKey !== 'key_user' && (
                <div className="form-group col-md-6 bmd-form-group mb-0">
                  <EditDisabled header={'Email'} text={email || ''} />
                </div>
              )}
              {/* Hide phone number field for creating/editing key users */}
              {(isEdit && roleKey !== 'key_user') || (!isEdit && !isKeyUser) && (
                <div className="form-group col-md-6 bmd-form-group mb-0">
                  <label htmlFor="phoneNumber">Phone number</label>
                  <input
                    ref={ref => setServerErrorDismiss(ref)}
                    type="text"
                    id="phoneNumber"
                    placeholder=""
                    defaultValue={phoneNumber}
                    onChange={e => onChange({ phoneNumber: e.target.value })}
                    className="form-control"
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 bmd-form-group mb-0">
                <label htmlFor="employeeIdentifier">Employee ID</label>
                <input
                  ref={ref => {
                    this.employeeIdentifier = ref
                    setServerErrorDismiss(ref)
                  }}
                  type="text"
                  className="form-control"
                  id="employeeIdentifier"
                  placeholder=""
                  defaultValue={employeeIdentifier}
                  onInput={defaultRequiredMessage}
                  onInvalid={defaultRequiredMessage}
                  onChange={e =>
                    onChange({ employeeIdentifier: e.target.value })
                  }
                />
                <div className="invalid-feedback">Employee ID required.</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12 bmd-form-group mb-0">
                {typeof id === 'undefined' && (
                  <AssignableRolesControl
                    persona={persona}
                    resourceIds={resourceIds}
                    onChange={values => onChange(values)}
                    isKeyUser={isKeyUser}
                  />
                )}
                {(hasMtiAdminRole ||
                  hasOrganizationAdminRole ||
                  hasStoreManagerRole ||
                  hasRegionalManagerRole) &&
                  !isEditProfileOnly &&
                  (
                    <AssignableRolesControl
                    persona={roleKey}
                    resourceIds={resourceIds}
                    onChange={values => onChange(values)}
                    roleResources={roleResources}
                    // Set isKeyUser to true if we're editing a key user, so that the Key User role displays.
                    // Otherwise, use the checkbox value for the create modal.
                    isKeyUser={isEdit && roleKey === 'key_user' ? true : isKeyUser}
                    />
                    )}
                {userEditLoading && (
                  <Fragment>
                    <Overlay />
                    <ProgressIndicator />
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFieldsSearchUser = () => {
    const { onChange, user } = this.props
    const {
      firstName,
      lastName,
      email,
      employeeIdentifier,
      phoneNumber,
      avatarUrl,
    } = user

    return (
      <div className="m-3">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="thumbnail mt-4">
                <img
                  src={avatarUrl || placeholder}
                  alt="Lights"
                  style={{ width: 160 }}
                />
              </div>
            </div>
            <div className="col-md-9">
              <div className="form-row">
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled header={'First name'} text={firstName || ''} />
                </div>
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled header={'Last name'} text={lastName || ''} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-3 bmd-form-group">
                  <div className="mb-3">
                    <EditDisabled header={'Password'} text={'********'} />
                  </div>
                </div>
                <div className="form-group col-md-3 bmd-form-group">
                  <EditDisabled header={'Passcode'} text={'****'} />
                </div>
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled
                    header={'Phone number'}
                    text={phoneNumber || ''}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 bmd-form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    ref={ref => {
                      this.email = ref
                      setServerErrorDismiss(ref)
                    }}
                    type="email"
                    className="form-control"
                    id="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    placeholder=""
                    defaultValue={email}
                    onChange={e => onChange({ email: e.target.value })}
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email address.
                  </div>
                </div>
                <div className="form-group col-md-6 bmd-form-group">
                  <label htmlFor="employeeIdentifier">Employee ID</label>
                  <input
                    ref={ref => {
                      this.employeeIdentifier = ref
                      setServerErrorDismiss(ref)
                    }}
                    type="text"
                    className="form-control"
                    id="employeeIdentifier"
                    placeholder=""
                    defaultValue={employeeIdentifier}
                    onChange={e =>
                      onChange({ employeeIdentifier: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderHeaderCreateEditUser = () => {
    const { title, confirmText } = this.props
    return (
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div>
          <SubmitWithHint
            text={confirmText}
            showHint={this.state.showValidationHint}
            onClick={this.isValid}
            onFocus={this.isValid}
          />
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.close}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { onConfirm } = this.props
    const { isCreateNewUser } = this.state
    return (
      <div className="modal-content">
        <form
          ref={ref => (this.formRef = ref)}
          style={{ textAlign: 'left' }}
          className="m-0 needs-validation was-validated"
          noValidate
          onSubmit={e => {
            e.preventDefault()
            if (isCreateNewUser) {
              if (this.isValid()) {
                this.setState({ serverValidationFailed: false })
                onConfirm()
              } else {
                this.highlightInvalid()
              }
            }
          }}
        >
          {this.renderHeaderCreateEditUser()}
          <div className="modal-body">
            {this.renderFieldsCreateEditUser()}
          </div>
        </form>
      </div>
    )
  }
}

CreateEditUserScreen.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onFind: PropTypes.func,
  isEditProfileOnly: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasMtiAdminRole: PropTypes.bool,
  userEditLoading: PropTypes.bool,
}

export default CreateEditUserScreen
